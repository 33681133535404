import GovernancaComponent from 'src/components/Institucional/SustentabilidadeBrasil/GovernancaComponent'
import Seo from 'src/components/seo/Seo'

function Ambiental() {
  return (
    <>
      <Seo title="Institucional | Decathlon" />
      <GovernancaComponent />
    </>
  )
}

export default Ambiental
